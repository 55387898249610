<template>
  <div id="app">
    <keep-alive>
      <router-view />
    </keep-alive>
    <!--<ResponsiveDebugger />-->
  </div>
</template>

<script>
import store from './store/index.js';
import { mapMutations } from 'vuex';

export default {
  name: 'App',
  computed: {
    country: function () {
      return store.state.country;
    },
    step: function () {
      return store.state.step;
    },
  },
  mounted() {
    if (!this.country) {
      this.initializeCountry();
    }
  },
  methods: {
    initializeCountry() {
      fetch('https://ipapi.co/json/')
        .then((d) => {
          console.log({ d });
          return d.json();
        })
        .then((t) => {
          const country = t.country;
          this.updateCountry(country);
        })
        .catch(() => {
          this.updateCountry('GB');
        });
    },
    ...mapMutations(['updateCountry']),
  },
  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: 'Hire Top Tech Talent Remotely',
    // all titles will be injected into this template
    titleTemplate: '%s  | MVP Match',
  },
};
</script>
