const event = 'funnelTrack'

export const sendEvent = function () {
  // console.log(event, ...arguments)
  if (window.dataLayer) {
    const e = {
      event: event,
      eventVariable1: arguments[0]
    }
    if (arguments[1]) {
      e.eventVariable2 = arguments[1]
    }
    // console.log(e)
    window.dataLayer.push(e)
  } else {
    console.error('error')
  }
}


// Step 1
export const eventSelectDevelopers = () => {
  sendEvent('funnel_select_developers')
}
export const eventSelectDesigners = () => {
  sendEvent('funnel_select_designers')
}
export const eventSelectProductManagers = () => {
  sendEvent('funnel_select_product_managers')
}
export const eventSelectOthers = () => {
  sendEvent('funnel_select_others')
}

// Step 2
export const eventSelectSkills = (skill) => {
  sendEvent('funnel_skills', skill)
}
export const eventWrittenSkills = (writtenSkill) => {
  sendEvent('funnel_skills_written', writtenSkill)
}

// Step 3
export const eventProjectStart = (option) => {
  sendEvent('funnel_project_start', option)
}
export const eventProjectFreelancers = (option) => {
  sendEvent('funnel_project_freelancers', option)
}
export const eventProjectTimeframe = (option) => {
  sendEvent('funnel_project_timeframe', option)
}

// Step 4 & Scale (Step 1)
export const eventFormSubmitted = (option) => {
  sendEvent('funnel_form_submitted', option)
}

// Step 5 Calendly
export const eventCalendlySubmitted = () => {
  sendEvent('funnel_calendly_submitted')
}
