<template>
  <h2
    class="text-2xl leading-8 font-bold tracking-tight sm:text-3xl sm:leading-9 md:pt-8 lg:pt-12 xl:pt-16"
  >
    <slot>Title</slot>
  </h2>
</template>

<script>
export default {
  name: 'MainTitle',
};
</script>

<style scoped></style>
