<template>
  <div class='max-w-full mx-auto lg:grid lg:grid-cols-3'>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MainWrapper',
}
</script>
