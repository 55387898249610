<template>
  <MainWrapper>
    <div
      class="lg:min-h-screen bg-white xl:px-16 lg:px-14 md:px-12 sm:px-4 px-4 lg:col-span-2"
    >
      <HeaderScale />

      <div class="sm:min-h-vh50 lg:max-w-3xl 3xl:pt-10 4xl:pt-20">
        <MainTitle>{{ translations.teams.title }}</MainTitle>

        <ValidationObserver ref="observer" tag="div">
          <form novalidate id="form" ref="form" @submit.prevent="submit()">
            <transition appear name="fade">
              <div class="mt-9 grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-3">
                <!-- First name -->
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  tag="div"
                  class="relative pb-5"
                >
                  <label
                    for="first_name"
                    class="block text-xs ml-3 text-primary"
                    >{{ translations.form.firstName }}</label
                  >
                  <div class="mt-1 relative">
                    <input
                      id="first_name"
                      v-model="form.first_name"
                      type="text"
                      name="first_name"
                      required
                      class="form-input"
                      v-bind:class="{
                        'border-red-300 text-red-900': errors[0],
                      }"
                    />

                    <div
                      class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      v-show="errors[0]"
                    >
                      <svg
                        class="h-5 w-5 text-red-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <p class="absolute bottom-0 left-0 ml-3 text-xs text-red-600">
                    {{ errors[0] }}
                  </p>
                </validation-provider>

                <!-- Last name -->
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  tag="div"
                  class="relative pb-5"
                >
                  <label
                    for="last_name"
                    class="block text-xs ml-3 text-primary"
                    >{{ translations.form.lastName }}</label
                  >
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="last_name"
                      v-model="form.last_name"
                      type="text"
                      name="last_name"
                      required
                      class="form-input"
                      v-bind:class="{
                        'border-red-300 text-red-900': errors[0],
                      }"
                    />
                    <div
                      class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                    >
                      <svg
                        v-show="errors[0]"
                        class="h-5 w-5 text-red-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <p class="absolute bottom-0 left-0 ml-3 text-xs text-red-600">
                    {{ errors[0] }}
                  </p>
                </validation-provider>

                <!-- Email -->
                <validation-provider
                  rules="required|email"
                  v-slot="{ errors }"
                  tag="div"
                  class="relative pb-5"
                >
                  <div :class="errors[0] ? 'pb-6' : ''">
                    <label
                      for="email"
                      class="block text-xs ml-3 text-primary"
                      >{{ translations.form.email }}</label
                    >
                    <div class="mt-1 relative rounded-md shadow-sm">
                      <input
                        id="email"
                        v-model="form.email"
                        type="text"
                        name="email"
                        required
                        class="form-input"
                        v-bind:class="{
                          'border-red-300 text-red-900': errors[0],
                        }"
                      />
                      <div
                        class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                      >
                        <svg
                          v-show="errors[0]"
                          class="h-5 w-5 text-red-500"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fill-rule="evenodd"
                            d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                            clip-rule="evenodd"
                          />
                        </svg>
                      </div>
                    </div>
                    <p
                      class="absolute left-0 ml-3 mt-1 text-xs text-red-600"
                      v-html="errors[0]"
                    ></p>
                  </div>
                </validation-provider>

                <div v-if="country">
                  <div class="flex justify-between">
                    <label
                      for="phone"
                      class="block text-xs ml-3 text-primary"
                      >{{ translations.form.phone }}</label
                    >
                    <span class="text-xs leading-5 -mt-1 text-gray-500">{{
                      translations.form.optional
                    }}</span>
                  </div>
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <vue-tel-input
                      id="phone"
                      v-bind="bindProps"
                      v-model="form.phone"
                      @country-changed="onPhoneCountryChange"
                      v-bind:wrapperClasses="{
                        'tel-form-wrapper': true,
                      }"
                      @input="onPhoneChange"
                    />
                  </div>
                </div>
                <div v-else>&nbsp;</div>

                <!-- Company -->
                <validation-provider
                  rules="required"
                  v-slot="{ errors }"
                  tag="div"
                  class="relative pb-5"
                >
                  <label
                    for="company"
                    class="block text-xs ml-3 text-primary"
                    >{{ translations.form.company }}</label
                  >
                  <div class="mt-1 relative rounded-md shadow-sm">
                    <input
                      id="company"
                      v-model="form.company"
                      type="text"
                      name="company"
                      required
                      class="form-input"
                      v-bind:class="{
                        'border-red-300 text-red-900': errors[0],
                      }"
                    />
                    <div
                      class="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none"
                    >
                      <svg
                        v-show="errors[0]"
                        class="h-5 w-5 text-red-500"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fill-rule="evenodd"
                          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7 4a1 1 0 11-2 0 1 1 0 012 0zm-1-9a1 1 0 00-1 1v4a1 1 0 102 0V6a1 1 0 00-1-1z"
                          clip-rule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                  <p class="absolute bottom-0 left-0 ml-3 text-xs text-red-600">
                    {{ errors[0] }}
                  </p>
                </validation-provider>

                <div>&nbsp;</div>

                <div class="md:col-start-1 md:col-end-3 flex items-start pb-5">
                  <div class="flex-shrink-0">
                    <span
                      role="checkbox"
                      tabindex="0"
                      @click="clickToggle"
                      @keypress="clickToggle"
                      aria-checked="false"
                      class="relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-primary focus:border-primary"
                      v-bind:class="{
                        'bg-gray-200': !toggle_active,
                        'bg-primary': toggle_active,
                      }"
                    >
                      <!-- On: "translate-x-5", Off: "translate-x-0" -->
                      <span
                        class="translate-x-0 inline-block h-5 w-5 rounded-full bg-white shadow transform transition ease-in-out duration-200"
                        v-bind:class="{
                          'translate-x-0': !toggle_active,
                          'translate-x-5': toggle_active,
                        }"
                      ></span>
                    </span>
                  </div>
                  <div class="pl-4">
                    <p class="text-sm">
                      {{ translations.form.consent }}
                      <a
                        href="https://mvpmatch.co/privacy-policy/"
                        target="_blank"
                        class="font-medium text-gray-700 underline"
                        >{{ translations.form.consentPrivacy }}</a
                      >
                      {{ translations.form.consentAnd }}
                      <a
                        href="https://mvpmatch.co/privacy-policy/"
                        target="_blank"
                        class="font-medium text-gray-700 underline"
                        >{{ translations.form.consentCookie }}</a
                      >.
                    </p>
                    <ValidationProvider
                      ref="gdpr"
                      v-slot="{ errors }"
                      :rules="{ required: { allowFalse: false } }"
                      name="GDPR"
                      mode="lazy"
                    >
                      <input
                        class="hidden"
                        type="checkbox"
                        name="gdpr"
                        :checked="toggle_active"
                        v-model="toggle_active"
                      />
                      <p class="mt-2 h-4 text-sm text-red-600" id="gdpr-error">
                        {{ errors[0] }}
                      </p>
                    </ValidationProvider>
                  </div>
                </div>
              </div>
            </transition>

            <!--  Hidden input fields for Autopilot to catch  -->
            <input
              name="type"
              type="text"
              class="hidden"
              v-model="requestType"
            />
            <input
              name="Service_Match"
              type="text"
              class="hidden"
              value="TEAMS"
            />
            <input name="gclid" type="text" class="hidden" v-model="gclid" />
            <input
              name="session_id"
              type="text"
              class="hidden"
              v-model="form.session_id"
            />

            <!-- utms -->
            <input
              name="utm_campaign"
              type="hidden"
              v-model="form.utm_campaign"
            />

            <input
              name="utm_content"
              type="hidden"
              v-model="form.utm_content"
            />

            <input name="utm_id" type="hidden" v-model="form.utm_id" />

            <input name="utm_medium" type="hidden" v-model="form.utm_medium" />

            <input name="utm_source" type="hidden" v-model="form.utm_source" />

            <input name="utm_term" type="hidden" v-model="form.utm_term" />

            <p
              v-if="apiError"
              class="m-2 block text-lg font-semibold text-center text-red-600"
              id="api-error"
            >
              {{ apiError }}
            </p>

            <BottomNavigation>
              <div>&nbsp;</div>
              <button
                type="submit"
                id="submit_teams_form"
                class="px-9 py-3 border border-transparent rounded-xl font-bold text-white bg-primary transition hover:bg-match-highlight"
              >
                {{ !spinner ? translations.form.submit : '' }}
                <span v-show="spinner" class="pl-12 spinner"></span>
              </button>
            </BottomNavigation>
            <BottomNavigationDummy />
          </form>
        </ValidationObserver>
      </div>
    </div>

    <!--  SIDE PANEL   -->
    <div class="lg:col-span-1 bg-gray-950">
      <transition appear name="fade-delay">
        <div class="flex flex-col w-full">
          <div class="flex flex-col items-center mt-8 lg:mt-16">
            <h1 class="text-white text-2.5xl text-center p-4 font-medium">
              Build and innovate faster with elite teams on-demand
            </h1>
            <div class="grid grid-cols-2 gap-16 justify-between mt-8 lg:mt-16">
              <img src="../assets/img/companies/sodexo.svg" alt="Sodexo" />
              <img src="../assets/img/companies/paddle.svg" alt="Paddle" />
              <img src="../assets/img/companies/heycar.svg" alt="HeyCar" />
              <img src="../assets/img/companies/wefox.svg" alt="WeFox" />
              <img src="../assets/img/companies/bumble.svg" alt="Bumble" />
              <img src="../assets/img/companies/klarna.svg" alt="Klarna" />
            </div>
          </div>
          <div
            class="flex flex-row justify-between bottom-0 lg:fixed mt-8 lg:mt-0"
          >
            <div class="bg-purple-400 flex flex-col">
              <p class="text-xl lg:text-lg leading-6 p-7 text-white">
                “ The team fits our needs better than anything we had seen from
                previous partners “
              </p>
              <div class="flex flex-row justify-between text-white px-7 mt-4">
                <div class="flex flex-col space-y-1 text-sm font-medium pb-4">
                  <p>Andre Vella</p>
                  <p>CTO of Heycar</p>
                </div>
                <div class="pt-6">
                  <img
                    src="../assets/img/companies/heycar-white.svg"
                    alt="HeyCar"
                  />
                </div>
              </div>
            </div>
            <div class="hidden lg:flex">
              <img
                src="../assets/img/Heycar_Andre Vella_Former Chief.png"
                alt="Andre Vella"
                class="w-96"
              />
            </div>
          </div>
        </div>
      </transition>
    </div>
  </MainWrapper>
</template>

<script>
import { mapMutations } from 'vuex';
import { mapGetters } from 'vuex';
import { translations } from '../store/translations';
import { eventFormSubmitted } from '../store/eventsHandler';
import { extend } from 'vee-validate';
import { email } from 'vee-validate/dist/rules';
import { DOMAINS } from '../store/constants';
import axios from 'axios';

export default {
  data: function () {
    return {
      translations,
      apiError: null,
      spinner: false,
      form: {
        first_name: this.$store.state.request.firstName,
        last_name: this.$store.state.request.lastName,
        email: this.$store.state.request.email,
        phone: this.$store.state.request.phone,
        company: this.$store.state.request.company,
        session_id: null,
        utm_campaign: this.$router.currentRoute.query.utm_campaign ?? '',
        utm_content: this.$router.currentRoute.query.utm_content ?? '',
        utm_source: this.$router.currentRoute.query.utm_source ?? '',
        utm_medium: this.$router.currentRoute.query.utm_medium ?? '',
        utm_id: this.$router.currentRoute.query.utm_id ?? '',
        utm_term: this.$router.currentRoute.query.utm_term ?? '',
      },
      toggle_active: false,
    };
  },
  computed: {
    bindProps: function () {
      return {
        mode: 'international',
        disabled: false,
        autofocus: false,
        disabledFetchingCountry: true,
        defaultCountry: this.$store.state.country,
        preferredCountries: ['DE', 'AT', 'CH', 'GB'],
        validCharactersOnly: true,
        name: 'phone',
        inputClasses: ['tel-form-input'],
        dropdownOptions: { disabledDialCode: false },
        inputOptions: { showDialCode: true },
        disabledFormatting: false,
        placeholder: '',
        required: true,
        enabledCountryCode: false,
        enabledFlags: true,
        onlyCountries: [],
        ignoredCountries: [],
        autocomplete: 'on',
        maxLen: 25,
      };
    },
    ...mapGetters([
      'requestType',
      'country',
      'gclid',
      'country',
      'phone',
      'utmCampaign',
      'utmContent',
      'utmId',
      'utmSource',
      'utmMedium',
      'utmTerm',
    ]),
  },
  mounted() {
    let gclId = this.$route.query.gclid;
    if (gclId) {
      this.updateGclid(gclId);
    }

    extend('email', {
      ...email,
      message: this.message,
      validate(value) {
        if (DOMAINS.some((domain) => value.includes(domain))) {
          this.message = translations.form.emailDomainError;
          return {
            valid: false,
          };
        }

        return {
          valid: value.length === 0 || email.validate(value),
        };
      },
    });
  },
  methods: {
    onPhoneCountryChange: function (countryOb) {
      // console.log(countryOb)
      if (!this.phone) {
        let v = '+';
        if (countryOb?.dialCode) {
          v = `+${countryOb.dialCode} `;
        }
        this.updatePhone(v);
      }
    },
    onPhoneChange: function (value) {
      this.updatePhone(value);
      this.form.phone = value;
    },
    clickToggle: function (e) {
      if (
        e.type !== 'keypress' ||
        (e.type === 'keypress' && (e.key === 'Enter' || e.key === ' '))
      ) {
        this.toggle_active = !this.toggle_active;
        this.form.session_id = window.AutopilotAnywhere?.sessionId;
      }
    },
    async submit() {
      const isValid = await this.$refs.observer.validate();
      if (!isValid) {
        // Do nothing
      } else {
        if (this.spinner) {
          return;
        }

        this.updateLastName(this.form.last_name);
        this.updateFirstName(this.form.first_name);
        this.updateEmail(this.form.email);
        this.updateCompany(this.form.company);
        this.updatePhone(this.form.phone);

        this.apiError = null;
        this.spinner = true;

        let form = document.getElementById('form');
        let formData = new FormData(form);

        axios
          .post(process.env.VUE_APP_API_URL + '/funnel', formData)
          //.post(process.env.VUE_APP_API_URL + '/client-request', formData)
          .then((response) => {
            eventFormSubmitted('teams');
            this.$router.push({ name: 'StepCalendly' });
            return response;
          })
          .catch((error) => {
            if (!error.response) {
              this.apiError = this.translations.axios.networkError;
            } else {
              this.apiError = this.translations.axios.serverError;
            }
          })
          .finally(() => {
            this.spinner = false;
          });
      }
    },
    ...mapMutations([
      'updateLastName',
      'updateFirstName',
      'updateEmail',
      'updateCompany',
      'updatePhone',
      'updateGclid',
      'updateUtmCampaign',
      'updateUtmContent',
      'updateUtmId',
      'updateUtmSource',
      'updateUtmMedium',
      'updateUtmTerm',
    ]),
  },
  metaInfo: {
    title: 'Teams',
  },
};
</script>
