<template>
  <div class='z-50 sticky lg:fixed bottom-0 left-0 right-0 lg:grid lg:grid-cols-3'>
    <div class='bg-white lg:col-span-2 flex border-t border-gray-200 justify-between py-8 lg:mx-14'>
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BottomNavigation',
}
</script>
