import Vue from 'vue';
import VueRouter from 'vue-router';
import Teams from '../views/Teams';
import StepCalendly from '../views/StepCalendly';
import store from '../store/index.js';

Vue.use(VueRouter);

const routes = [
  // if route is '/', redirect to Teams
  {
    path: '/',
    redirect: '/teams',
  },

  // Teams landing page
  {
    path: '/teams',
    name: 'Teams',
    component: Teams,
    beforeEnter: (to, from, next) => {
      store.commit('updateStep', 1);
      store.commit('updateRequestType', 'Teams');
      next();
    },
  },

  // Calendly page
  {
    path: '/success',
    name: 'StepCalendly',
    component: StepCalendly,
    beforeEnter: (to, from, next) => {
      if (store.state.request.requestType === '') {
        next({ name: 'Step1' });
      } else {
        next();
      }
    },
  },

  /** step when user had not finish creating meeting for first time.
   * So Ortto will send email as an reminder to user with link to this page
   */

  {
    path: '/calendly',
    name: 'StepCalendly',
    component: StepCalendly,
    beforeEnter: (to, from, next) => {
      // if emails or name is missing, redirect to Step 1
      if (!to.query.email || !to.query.name) {
        next({ name: 'Step1' });
      }

      store.commit('updateEmail', to.query.email);
      if (to.query.name) {
        const name = to.query.name.split(' ');
        store.commit('updateFirstName', name[0] || '');
        store.commit('updateLastName', name[1] || '');
      }
      next();
    },
  },

  // 404 not found redirects to Step 1
  {
    path: '*',
    name: '404',
    beforeEnter: (to, from, next) => {
      next({ name: 'Step1' });
    },
  },
];

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
