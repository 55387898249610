<template>
  <div class='z-50 fixed bottom-0 bg-white p-2 opacity-20 pointer-events-none text-sm'>
    <div class='sm:hidden'>XS (&gt; 0)</div>
    <div class='hidden sm:block md:hidden'>SM (&gt; 568px)</div>
    <div class='hidden md:block lg:hidden'>MD (&gt; 768px)</div>
    <div class='hidden lg:block xl:hidden'>LG (&gt; 1024px)</div>
    <div class='hidden xl:block 2xl:hidden'>XL (&gt; 1280px)</div>
    <div class='hidden 2xl:block 3xl:hidden'>2XL (&gt; 1366px)</div>
    <div class='hidden 3xl:block 4xl:hidden'>3XL (&gt; 1440px)</div>
    <div class='hidden 4xl:block'>4XL (&gt; 1440px)</div>
  </div>
</template>

<script>
export default {
  name: 'ResponsiveDebugger',
}
</script>
