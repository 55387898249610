<template>
  <div class='lg:min-h-screen max-w-full mx-auto'>
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'MainWrapperCalendly',
}
</script>
