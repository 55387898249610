<template>
  <MainWrapperCalendly>
    <div class="bg-white px-4 sm:px-4 lg:px-14 md:px-12 xl:px-16">
      <Header />
    </div>
    <div class="xl:px-16 lg:px-14 md:px-12 sm:px-4 px-4 text-center">
      <MainTitle>{{ translations.pageCalendly.title }}</MainTitle>
      <MainTitle>{{ translations.pageCalendly.subtitle }}</MainTitle>
    </div>
    <div id="calendlyWrapper" class="calendly-inline"></div>
  </MainWrapperCalendly>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { translations } from '../store/translations';
import axios from 'axios';
import { eventCalendlySubmitted } from '../store/eventsHandler';

export default {
  data: function () {
    return {
      sendEmail: '',
      translations,
    };
  },
  computed: {
    ...mapGetters(['firstName', 'lastName', 'email', 'phone']),
  },
  mounted() {
    window.addEventListener('message', this.isCalendlyForm);
    let recaptchaScript = document.createElement('script');
    recaptchaScript.setAttribute(
      'src',
      'https://assets.calendly.com/assets/external/widget.js'
    );
    recaptchaScript.addEventListener('load', () => {
      window.Calendly.initInlineWidget({
        url: process.env.VUE_APP_CALENDLY_URL, //,
        parentElement: document.getElementById('calendlyWrapper'),
        prefill: {
          name: `${this.firstName} ${this.lastName}`,
          email: this.email,
          customAnswers: {
            a1: this.phone,
          },
        },
      });
      this.sendEmail = this.email;
      this.resetForm();
    });
    document.head.appendChild(recaptchaScript);
  },
  beforeDestroy() {
    window.removeEventListener('message', this.isCalendlyForm);
  },
  methods: {
    isCalendlyForm(e) {
      if (e.data.event && e.data.event === 'calendly.event_scheduled') {
        let formData = new FormData();
        formData.append('email', this.sendEmail);
        formData.append('uri', e.data.payload?.event?.uri);

        eventCalendlySubmitted();

        axios
          .post(process.env.VUE_APP_API_URL + '/calendly/store', formData)
          .then(() => {
            // redirect to thank you page or to home
            //this.$router.push({ name: 'Step1' });
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
    ...mapMutations(['resetForm']),
  },
};
</script>
<style>
.calendly-inline {
  height: 1500px;
}
@media screen and (min-width: 699px) {
  .calendly-inline {
    height: 1500px;
  }
}
@media screen and (min-width: 1000px) {
  .calendly-inline {
    height: 800px;
  }
}
</style>
