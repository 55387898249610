import Vue from 'vue';
import VueMeta from 'vue-meta';
import { ValidationProvider, extend } from 'vee-validate';
import { required, email } from 'vee-validate/dist/rules';
import { ValidationObserver } from 'vee-validate';
import VueCalendly from 'vue-calendly';
import VueTelInput from 'vue-tel-input';
import './assets/tailwind.css';
import router from './router';
import store from './store';
import App from './App';
import Header from './components/Header';
import HeaderScale from './components/HeaderScale';
import HeaderProgressBar from './components/HeaderProgressBar';
import LogosByCountry from './components/LogosByCountry'
import MainWrapper from './components/layout/MainWrapper'
import MainWrapperCalendly from './components/layout/MainWrapperCalendly'
import BottomNavigation from './components/layout/BottomNavigation'
import BottomNavigationDummy from './components/layout/BottomNavigationDummy'
import ResponsiveDebugger from './components/ResponsiveDebugger'
import MainTitle from './components/layout/MainTitle'

Vue.use(VueMeta);
Vue.use(VueCalendly);
Vue.use(VueTelInput);

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
window.axios.defaults.withCredentials = true;

Vue.config.productionTip = false
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);
Vue.component('Header', Header);
Vue.component('HeaderScale', HeaderScale);
Vue.component('HeaderProgressBar', HeaderProgressBar);
Vue.component('LogosByCountry', LogosByCountry);
Vue.component('MainWrapper', MainWrapper);
Vue.component('MainWrapperCalendly', MainWrapperCalendly);
Vue.component('BottomNavigation', BottomNavigation);
Vue.component('BottomNavigationDummy', BottomNavigationDummy);
Vue.component('ResponsiveDebugger', ResponsiveDebugger);
Vue.component('MainTitle', MainTitle);

extend('required', {
  ...required,
  message: 'This field is required'
});
extend('email', {
  ...email,
  message: 'Email is not valid'
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

