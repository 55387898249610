<template>
  <div>
    <!--  GERMAN - DE  -->
    <div v-if='country === "DE"' class='z-index-1 relative grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-2 items-center justify-center gap-x-10 gap-y-16 lg:gap-x-14 xl:gap-x-16 lg:gap-y-18 xl:gap-y-20 px-8 py-24 lg:p-8'>
      <div>
        <img src='logos/heycar.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/oliver_wyman.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/merck.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/voya.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/kpmg.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/visa.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/deutsche_bank.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/henkel.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/signal_iduna.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/paddle.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
    </div>
    <!--  INTERNATIONAL  -->
    <div v-if='country !== "DE"' class='z-index-1 relative grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-2 items-center justify-center gap-x-10 gap-y-16 lg:gap-x-14 xl:gap-x-16 lg:gap-y-18 xl:gap-y-20 px-8 py-24 lg:p-8'>
      <div>
        <img src='logos/sodexo.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/oliver_wyman.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/bnp_paribas.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/mckinsey.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/kpmg.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/visa.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/merck.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/cbre.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/blinkist.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4' />
      </div>
      <div>
        <img src='logos/pwc.svg' alt='' class='w-logo 2xl:w-logo2 3xl:w-logo3 4xl:w-logo4'  />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters([
      'country',
    ])
  },
}
</script>
