export const DEVELOPER = 'Developer';
export const DESIGNER = 'Designer';
export const PRODUCT_MANAGER = 'Productmanager';
export const OTHER = 'Other';
export const DOMAINS = [
  '@gmail',
  '@outlook',
  '@hotmail',
  '@yahoo',
  '@icloud',
  '@aol',
  '@msn',
];
