<template>
  <div class='flex justify-between items-center py-8 md:space-x-10' style='height: 110px'>
    <router-link to='/' aria-label='MVP Match'><img src='logo.svg' style='width: 152px;' alt='MVP Match'></router-link>
    <nav class='flex flex-col items-end space-y-4'>
      <p class='text-sm leading-5 font-bold text-teal-500'>{{ translations.header.step }} {{ step }}/4</p>
      <div class='flex gap-0.5 w-full'>
        <div class='w-3 rounded-sm h-1 transition-width duration-400 w-3 bg-green-200' v-bind:class="[{ 'w-10 bg-teal-500': step === 1}]">&nbsp;</div>
        <div class='w-3 rounded-sm h-1 transition-width duration-400 w-3 bg-green-200' v-bind:class="[{ 'w-10 bg-teal-500': step === 2}]">&nbsp;</div>
        <div class='w-3 rounded-sm h-1 transition-width duration-400 w-3 bg-green-200' v-bind:class="[{ 'w-10 bg-teal-500': step === 3}]">&nbsp;</div>
        <div class='w-3 rounded-sm h-1 transition-width duration-400 w-3 bg-green-200' v-bind:class="[{ 'w-10 bg-teal-500': step === 4}]">&nbsp;</div>
      </div>
    </nav>
  </div>
</template>
<script>
import { translations } from '../store/translations'

export default {
  data: function () {
    return {
      translations,
    }
  },
  computed: {
    step: function () {
      return this.$store.state.step
    },
  },
}
</script>
