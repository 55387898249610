export const translations = {
  form: {
    fieldRequiredError: 'This field is required',
    emailError: 'Email is not valid',
    emailDomainError:
      '<span id="email-domain-error">Please provide your business email address. If you are looking to join our talent network, <a id="email-domain-error-redirect" style="border-bottom: 1px solid" href="https://mvpmatch.co/for-freelancers">click here</a>.</span>',
    firstName: 'First Name',
    lastName: 'Last Name',
    email: 'Business email',
    phone: 'Phone',
    optional: 'Optional',
    company: 'Company',
    consent:
      'I am hereby consenting that MVPF Global Talent Solutions GmbH can process my personal data for the purpose of making me an offer for their services. Read our ',
    consentPrivacy: 'Privacy policy',
    consentAnd: 'and',
    consentCookie: 'Cookie Policy',
    submit: 'Submit',
    next: 'Next',
    back: 'Back',
  },
  header: {
    step: 'Step',
  },
  pageScale: {
    title: 'Join Match Talent Cloud now',
    sidebarText: 'Flexible staffing',
    sidebarText1: 'for',
    sidebarText2: 'high-growth',
    sidebarText3: 'teams',
  },
  hub: {
    title: 'Schedule a call with your Match Hub advisor',
    sidebarText: 'Build top teams',
    sidebarText1: 'anywhere',
  },
  teams: {
    title: 'Schedule a call with your Match Teams advisor',
    sidebarText: 'Hire a team',
    sidebarText1: 'to build',
    sidebarText2: 'your dream product',
  },
  pageStep1: {
    title: 'What kind of experts do you need?',
    devs: 'Engineers & Developers',
    devsDesc: 'Software, DevOps, Data Science, QA, Security, and more',
    designers: 'Designers',
    designersDesc: 'UX, UI, Web, Branding, Product Design, and more',
    productManagers: 'Product Managers',
    productManagersDesc:
      'Product Managers/Owners, Agile/Scrum Coaches, Business Analysts, and more',
    others: 'Other',
    othersDesc: 'Marketing, CRM, Blockchain, Web3, and more',
  },
  pageStep2: {
    title: 'What skills are you looking for?',
    skillsPlaceholder: 'Type here or choose from the list below',
    skillsTitleDevs: 'Popular skills in Engineering & Development',
    skillsTitleDesigners: 'Popular skills in Design',
    skillsTitleProductManagers: 'Popular skills in Product Management',
    skillsTitleOther: 'Popular skills',
    sidebarTitle1: '6000+',
    sidebarDesc1: 'World’s top tech talent',
    sidebarTitle2: '200+',
    sidebarDesc2: 'Products built',
    sidebarTitle3: '94%',
    sidebarDesc3: 'Client satisfaction rate',
  },
  pageStep3: {
    title: 'Tell us about your project',
    projectStart: {
      title: 'When would you like to start?',
      option1: 'Right away',
      option2: 'Within 1 month',
      option3: 'I’m not sure',
    },
    quantity: {
      title: 'How many experts do you need?',
      option1: '1',
      option2: '2',
      option3: '3',
      option4: '4 or more',
    },
    duration: {
      title: 'How long do you need this talent for?',
      option1: 'Less than a month',
      option2: '1-3 months',
      option3: 'More than 3 months',
    },
    sidebarTestimonial: {
      name: 'Andre Vella',
      title: 'Chief Product Officer',
      testimonial:
        '"The delivery has been phenomenal! We’ve never seen such a fast turnaround with such amazing profiles."',
      logo: 'logos/heycar.svg',
    },
  },
  pageStep4: {
    title: 'Almost done! How can we contact you?',
    summary: {
      title: 'Your request',
      typeFreelancers: 'Talent Requested:',
      devs: 'Engineers & Developers',
      designers: 'Designers',
      productManagers: 'Product Managers',
      others: 'Other',
      skills: 'Skills:',
      startTime: 'Start date:',
      quantity: 'How Many:',
      duration: 'Duration:',
    },
  },
  pageCalendly: {
    title: 'Thank you for choosing Match!',
    subtitle:
      'Next, please schedule a call with your dedicated talent advisor.',
  },
  axios: {
    networkError: 'Network Error, please try again later',
    serverError: 'Server Error, please try again later',
  },
};
