import Vue from 'vue';
import Vuex from 'vuex';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { DEVELOPER, DESIGNER, PRODUCT_MANAGER, OTHER } from './constants';

Vue.use(Vuex);
Vue.use(VueAxios, axios);

function initialState() {
  return {
    request: {
      requestType: '', // Type of Request (Freelancer or Scale or Hub or Teams)
      resources: ['', '', '', ''], // Type of Freelancers (Developer, Designer, etc...)

      skills: '', // User defined input for skills
      selectedSkills: [], // User defined selection of skills

      startProject: '', // User defined when to start project
      duration: '', // User defined duration of need for freelancers
      quantity: '', // User defined quantity of freelancers

      firstName: '', // User defined first name
      lastName: '', // User defined last name
      email: '', // User defined email
      phone: '', // User defined phone number
      company: '', // User defined company,
      utm_campaign: '', // User defined utm_campaign
      utm_content: '', // User defined utm_content
      utm_id: '', // User defined utm_id
      utm_source: '', // User defined utm_source
      utm_medium: '', // User defined utm_medium
      utm_term: '', // User defined utm_term
    },

    step: 1,
    gclid: '',
    country: sessionStorage.getItem('country'),
  };
}
export default new Vuex.Store({
  state: initialState,
  mutations: {
    parseProductQuery(state, product) {
      // lets convert single one into array
      const resources = ['', '', '', ''];
      let dirty = false;

      if (typeof product === 'string') {
        product = [product];
      }
      if (Array.isArray(product)) {
        product.map((p) => {
          switch (p) {
            case DEVELOPER: {
              resources[0] = p;
              dirty = true;
              break;
            }
            case DESIGNER: {
              resources[1] = p;
              dirty = true;
              break;
            }
            case PRODUCT_MANAGER: {
              resources[2] = p;
              dirty = true;
              break;
            }
            case OTHER: {
              resources[3] = p;
              dirty = true;
              break;
            }
            default: {
              // do nothing
              break;
            }
          }
        });
      }
      if (dirty) {
        // console.log('resources', resources)
        state.request.resources = resources;
      }
    },
    updateRequestType(state, payload) {
      state.request.requestType = payload;
    },
    updateResources(state, payload) {
      state.request.resources.splice(payload.id, 1, payload.resource);
    },

    updateSkills(state, skills) {
      state.request.skills = skills;
    },
    updateSelectedSkills(state, skills) {
      state.request.selectedSkills = skills;
    },

    updateStartProject(state, start) {
      state.request.startProject = start;
    },
    updateQuantity(state, quantity) {
      state.request.quantity = quantity;
    },
    updateDuration(state, duration) {
      state.request.duration = duration;
    },

    updateFirstName(state, payload) {
      state.request.firstName = payload;
    },
    updateEmail(state, payload) {
      state.request.email = payload;
    },
    updateLastName(state, payload) {
      state.request.lastName = payload;
    },
    updatePhone(state, payload) {
      state.request.phone = payload;
    },
    updateCompany(state, payload) {
      state.request.company = payload;
    },

    updateGclid(state, gclid) {
      state.gclid = gclid;
    },
    updateStep(state, step) {
      state.step = step;
    },
    updateCountry(state, country) {
      state.country = country;
      sessionStorage.setItem('country', country);
    },

    updateUtmCampaign(state, payload) {
      state.request.utm_campaign = payload;
    },

    updateUtmContent(state, payload) {
      state.request.utm_content = payload;
    },

    updateUtmId(state, payload) {
      state.request.utm_id = payload;
    },

    updateUtmSource(state, payload) {
      state.request.utm_source = payload;
    },

    updateUtmMedium(state, payload) {
      state.request.utm_medium = payload;
    },

    updateUtmTerm(state, payload) {
      state.request.utm_term = payload;
    },

    resetForm(state) {
      state.request = {
        requestType: state.request.requestType, // inherit Type of Request
        resources: ['', '', '', ''],

        skills: '',
        selectedSkills: [],

        startProject: '',
        duration: '',
        quantity: '',

        firstName: '',
        lastName: '',
        email: '',
        phone: '',
        company: '',
        utm_campaign: '',
        utm_content: '',
        utm_id: '',
        utm_source: '',
        utm_medium: '',
        utm_term: '',
      };
      state.step = 1;
    },
  },
  getters: {
    requestType: (state) => {
      return state.request.requestType;
    },
    resources: (state) => {
      return state.request.resources;
    },

    skills: (state) => {
      return state.request.skills;
    },
    selectedSkills: (state) => {
      return state.request.selectedSkills;
    },

    startProject: (state) => {
      return state.request.startProject;
    },
    quantity: (state) => {
      return state.request.quantity;
    },
    duration: (state) => {
      return state.request.duration;
    },

    firstName: (state) => {
      return state.request.firstName;
    },
    lastName: (state) => {
      return state.request.lastName;
    },
    email: (state) => {
      return state.request.email;
    },
    phone: (state) => {
      return state.request.phone;
    },
    company: (state) => {
      return state.request.company;
    },

    step: (state) => {
      return state.step;
    },
    gclid: (state) => {
      return state.gclid;
    },
    country: (state) => {
      return state.country;
    },

    utmCampaign: (state) => {
      return state.request.utm_campaign;
    },

    utmContent: (state) => {
      return state.request.utm_content;
    },

    utmId: (state) => {
      return state.request.utm_id;
    },

    utmSource: (state) => {
      return state.request.utm_source;
    },

    utmMedium: (state) => {
      return state.request.utm_medium;
    },

    utmTerm: (state) => {
      return state.request.utm_term;
    },
  },
  modules: {},
});
